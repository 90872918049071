<template>
  <div class="login-box">
    <div class="form-box bg_fff br_10">
      <div class="title">武侯教育党建平台</div>
      <p class="fs_18 m_tb_20 fw_bold">登录</p>
      <el-form
        :model="loginForm"
        ref="loginForm"
        :rules="loginFormRules">
        <el-form-item prop="username">
          <el-input
            type="text"
            prefix-icon="el-icon-user"
            placeholder="请输入账号"
            v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            type="password"
            prefix-icon="el-icon-unlock"
            placeholder="请输入密码"
            v-model="loginForm.password"></el-input>
        </el-form-item>
      </el-form>
      <button
        @keydown.enter="loginSubmit_click"
        class="login-btn c_fff pointer"
        @click="loginSubmit_click">
        登录
      </button>
    </div>
  </div>
</template>

<script>
import md5 from 'js-md5';
import jsCookie from 'js-cookie';
import { sessionSet } from '@/utils/local.js';
import { loginByUsername_api, refreshToken } from '@/api/mine';
import { deptInfo } from '@/api/resourceCenter/school';
import { UPDATE_USER_INFO } from '@/constants/mutation-types';
export default {
  name: 'PartyLogin',
  data() {
    return {
      loginForm: {
        username: null,
        password: null,
        types: 1,
        tenantId: '501654',
      },
      loginFormRules: {
        username: [{ required: true, message: '请输入账号', target: 'blur' }],
        password: [{ required: true, message: '请输入密码', target: 'blur' }],
      },
    };
  },
  methods: {
    loginSubmit_click() {
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          let params = JSON.parse(JSON.stringify(this.loginForm));
          jsCookie.set('userInfoSan', JSON.stringify(params), {
            domain: '.wuhousmartedu.com',
          });
          params.password = md5(params.password);
          loginByUsername_api(params)
            .then(async (res) => {
              if (res.error_code === 400) {
                return this.$message.error('用户名或密码不正确');
              }
              this.$message.success('登录成功');
              this.saveInfo(res);
              this.checkToken(res);
              await this.getDeptInfo(res.dept_id);
              this.$router.push({ path: '/party/index' });
            })
            .catch(() => {
              this.$message.error('登录失败，请稍后重试');
            });
        }
      });
    },
    // 获取学校信息
    async getDeptInfo(id) {
      await deptInfo({ id }).then((res) => {
        sessionSet('deptInfo', res.data);
      });
    },
    //登录成功后监测token过期处理
    checkToken(res) {
      //过期时间戳
      let loginTime = new Date().getTime() + res.expires_in * 1000;
      let timer = setInterval(() => {
        //当过期时间小于十分钟时刷新token
        if (loginTime - new Date().getTime() < 60 * 1000 * 10) {
          refreshToken(res.refresh_token, res.tenant_id).then((res) => {
            this.saveInfo(res);
            loginTime = new Date().getTime() + res.expires_in * 1000;
          });
        }
      }, 30000);
      sessionSet('timer', timer);
    },
    saveInfo(info) {
      this.$store.commit(UPDATE_USER_INFO, {
        userId: info.user_id,
        userName: info.nick_name,
        userAvatar: info.avatar,
        deptId: info.dept_id,
        roleName: info.role_name,
      });
      let token = {
        access_token: info.access_token,
        refresh_token: info.refresh_token,
        token_type: info.token_type,
      };
      sessionSet('tk', token);
      sessionSet('userInfo', info);
      sessionSet('userId', info.user_id);
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: Rajdhani;
  src: url('../../../assets/fonts/YouSheBiaoTiHei-2.ttf');
}
.login-box {
  height: 100vh;
  background: url('../../../assets/image/partyLoginBg.png');
  background-size: 100% 100%;
  position: relative;
}
.form-box {
  width: 400px;
  height: 380px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
}

.title {
  font-family: Rajdhani;
  font-size: 40px;
  color: #dd2622;
  text-align: center;
}
.login-btn {
  width: 300px;
  height: 40px;
  background: linear-gradient(180deg, #ffbe73, #dd2622 100%);
  border-radius: 4px;
  border: none;
}
</style>
